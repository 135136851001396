import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material"
import AddressImage from "../../../Images/Address.svg"
import EditIcon from "@mui/icons-material/Edit"
import DateImage from "../../../Images/Date.svg"
import MethodImage from "../../../Images/method.svg"

import React from "react"
import moment from "moment"

const CheckoutPickupInformation = ({ checkoutData }) => {
  return (
    <>
      <Grid item>
        <Card sx={{ width: "640px", height: "255px" }}>
          <CardContent>
            <Grid item>
              <Typography variant="h6">Pickup Information</Typography>
            </Grid>
            <Grid
              display={"flex"}
              justifyContent="space-between"
              alignContent={"center"}
            >
              <Grid
                display={"flex"}
                justifyContent={"flex-start"}
                alignContent="center"
                flexDirection={"row"}
                gap={3}
                paddingTop={2}
              >
                <Grid item>
                  <CardMedia
                    component="img"
                    sx={{ width: 40 }}
                    image={AddressImage}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    gutterBottom
                  >
                    {checkoutData?.pickup_address?.address}
                  </Typography>
                  <Typography variant="body2">
                    {checkoutData?.pickup_address?.name}
                  </Typography>
                </Grid>
              </Grid>
              <Button>
                <EditIcon sx={{ color: "black" }} />
              </Button>
            </Grid>
            <Grid item>
              <Grid
                display={"flex"}
                justifyContent="space-between"
                alignContent={"center"}
              >
                <Grid
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignContent="center"
                  flexDirection={"row"}
                  gap={3}
                  paddingTop={2}
                >
                  <Grid item>
                    <CardMedia
                      component="img"
                      sx={{ width: 40 }}
                      image={DateImage}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Pickup date and time
                    </Typography>
                    <Typography variant="body2">
                      {" "}
                      {moment(checkoutData?.pickup_date?.start_date).format(
                        "dddd , MMMM , Do YYYY , h:mm:ss a"
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Button>
                  <EditIcon sx={{ color: "black" }} />
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                display={"flex"}
                justifyContent="space-between"
                alignContent={"center"}
              >
                <Grid
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignContent="center"
                  flexDirection={"row"}
                  gap={3}
                  paddingTop={2}
                >
                  <Grid item>
                    <CardMedia
                      component="img"
                      sx={{ width: 40 }}
                      image={MethodImage}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Pickup Method
                    </Typography>
                    <Typography variant="body2">
                      {checkoutData?.pickup_method?.typeOfMethod}
                    </Typography>
                  </Grid>
                </Grid>
                <Button>
                  <EditIcon sx={{ color: "black" }} />
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

export default CheckoutPickupInformation
