import React from "react"
import Stovooimage from "../../Images/Vector.svg"
import AvatarImage from "../../Images/Rectangle 8221.png"
import "./Navbar.css"
import { Link, Typography, Avatar, Button } from "@mui/material"
import { color } from "@mui/system"

const Navbar = () => {
  return (
    <>
      <div className="box">
        <div className="logo-section-nav">
          <div className="logo-image">
            <img src={Stovooimage} />
          </div>
          <div className="logo-title">
            <Link href="/" underline="none" color="black">
              <Typography variant="h4">Stoovo</Typography>
            </Link>
          </div>
        </div>
        <div className="support-section">
          <div className="support-link">
            <Button type="submit" className="support-link-button">
              <Typography variant="body1">Support</Typography>
            </Button>
          </div>
          <div className="avatar">
            <Button type="submit" href="/profile">
              <Avatar alt=" " src={AvatarImage} />
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar
