import { Grid, Typography } from "@mui/material"
import React from "react"

const OrderHistoryPackages = ({ packageData }) => {
  return (
    <>
      <Grid
        display={"flex"}
        justifyContent={"flex-start"}
        alignContent="center"
        flexDirection={"row"}
        gap={3}
        padding={2}
      >
        <Grid display={"flex"} flexDirection="row" gap={4}>
          <Grid item>
            <Typography variant="body-2">
              SoftPolyBag - {packageData?.pickup_type?.soft_poly_bag || 0}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body-1">
              Box - {packageData?.pickup_type?.box || 0}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body-1">
              MailingTube - {packageData?.pickup_type?.mailing_tube || 0}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body-1">
              Flat Enevlope - {packageData?.pickup_type?.flat_envelope || 0}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default OrderHistoryPackages
