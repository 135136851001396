import React, { useContext, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardContent,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material"
import { Divider } from "@mui/material"
import { getOrderCheckout, getPayment, paymentStatus } from "../../../api"
import { useMutation, useQuery } from "@tanstack/react-query"
import { MultiStepContext } from "../../../Context/StepContext"
import { useNavigate, useParams } from "react-router-dom"

const OrderSummary = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [alignment, setAlignment] = useState(1)
  const { setActiveStep } = useContext(MultiStepContext)
  const {
    data: response,
    isLoading,
    isError,
  } = useQuery(["get-payment"], () => getPayment(id))

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment)
  }

  useEffect(() => {
    setActiveStep(6)
  })

  // react-query
  const { mutate } = useMutation((data) => paymentStatus(id, data), {
    onSuccess: (res) => {
      navigate(`/order-tracking/${id}`)
    },
    onError: (err) => {
      console.log(err)
    },
  })

  if (isLoading) {
    return <div>Loading...</div>
  }
  const totalFeeDevNum = response.data.amount
  const totalFee = totalFeeDevNum + alignment

  const handleSubmit = (e) => {
    e.preventDefault()
    mutate(totalFee)
  }

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        <Card sx={{ width: "304px", height: "380px" }}>
          <CardContent>
            <Grid item>
              <Typography variant="h6">Order Summary</Typography>
            </Grid>
            <Grid
              display={"flex"}
              justifyContent="space-between"
              alignContent={"center"}
            >
              <Grid
                display={"flex"}
                justifyContent={"center"}
                alignContent="center"
                paddingLeft={1}
              >
                <Grid item>
                  <Typography variant="body1" color={"text.secondary"}>
                    packages
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              justifyContent="space-between"
              alignContent={"center"}
              paddingTop={2}
            >
              <Grid
                display={"flex"}
                justifyContent={"center"}
                alignContent="center"
                paddingLeft={1}
              >
                <Grid
                  display={"flex"}
                  justifyContent="center"
                  alignItems={"center"}
                  gap={18}
                >
                  <Typography variant="body1" color={"text.secondary"}>
                    Delivery Fee
                  </Typography>
                  <Typography variant="body1">${totalFeeDevNum}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              display={"flex"}
              justifyContent="space-between"
              alignContent={"center"}
              flexDirection={"column"}
            >
              <Grid
                display={"flex"}
                justifyContent="space-between"
                alignContent={"center"}
                paddingTop={2}
              >
                <Grid
                  display={"flex"}
                  justifyContent={"center"}
                  alignContent="center"
                  paddingLeft={1}
                >
                  <Grid
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                    gap={15}
                  >
                    <Typography variant="body1" color={"text.secondary"}>
                      Stoover tip
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                display={"flex"}
                justifyContent="space-evenly"
                alignItems={"center"}
                paddingTop={2}
              >
                <Grid item>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    onChange={handleChange}
                    exclusive
                  >
                    <ToggleButton value={1}>$1</ToggleButton>
                    <ToggleButton value={2}>$2</ToggleButton>
                    <ToggleButton value={3}>$3</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item>
                  <Button variant="contained" value="other">
                    Other
                  </Button>
                </Grid>
              </Grid>
              <Grid item paddingTop={2}>
                <Divider />
              </Grid>
              <Grid
                display={"flex"}
                justifyContent="space-between"
                alignContent={"center"}
                paddingTop={2}
              >
                <Grid
                  display={"flex"}
                  justifyContent={"center"}
                  alignContent="center"
                  paddingLeft={1}
                >
                  <Grid
                    display={"flex"}
                    justifyContent="center"
                    alignItems={"center"}
                    gap={21}
                  >
                    <Typography variant="body1" color={"text.secondary"}>
                      Total Fee
                    </Typography>
                    <Typography variant="body1">${totalFee}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              display="flex"
              justifyContent={"center"}
              alignContent="center"
              padding={5}
            >
              <Grid item>
                <Button type="submit" variant="contained">
                  Pay
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </>
  )
}

export default OrderSummary
