import { Alert, Link, Typography } from "@mui/material";
import React from "react";

const ForgotPasswordSuccess = () => {
  return (
    <>
      <Alert severity="success">
        Checkout your email
        <Typography variant="body2">
          We've sent you an email with a link to reset your password{" "}
        </Typography>
        <Link href="login" underline="none" sx={{ color: "darkgreen" }}>
          Click to get redirected on Login
        </Link>
      </Alert>
    </>
  );
};

export default ForgotPasswordSuccess;
