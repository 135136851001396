import React from "react"
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material"
import AddressImage from "../../../Images/Address.svg"
import EditIcon from "@mui/icons-material/Edit"
import DateImage from "../../../Images/Date.svg"
import MethodImage from "../../../Images/method.svg"
import moment from "moment"

const CheckoutDeliveryInformation = ({ checkoutData }) => {
  return (
    <>
      <Grid item>
        <Grid item>
          <Card sx={{ width: "640px", height: "255px" }}>
            {checkoutData && (
              <CardContent>
                <Grid item>
                  <Typography variant="h6">Delivery Information</Typography>
                </Grid>
                <Grid
                  display={"flex"}
                  justifyContent="space-between"
                  alignContent={"center"}
                >
                  <Grid
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignContent="center"
                    flexDirection={"row"}
                    gap={3}
                    paddingTop={2}
                  >
                    <Grid item>
                      <CardMedia
                        component="img"
                        sx={{ width: 40 }}
                        image={AddressImage}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        {checkoutData?.delivery_address?.address || ""}
                      </Typography>
                      <Typography variant="body2">
                        {" "}
                        {checkoutData?.delivery_address?.name || ""} |{" "}
                        {checkoutData?.delivery_address?.phone_num || ""}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button>
                    <EditIcon sx={{ color: "black" }} />
                  </Button>
                </Grid>
                <Grid item>
                  <Grid
                    display={"flex"}
                    justifyContent="space-between"
                    alignContent={"center"}
                  >
                    <Grid
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignContent="center"
                      flexDirection={"row"}
                      gap={3}
                      paddingTop={2}
                    >
                      <Grid item>
                        <CardMedia
                          component="img"
                          sx={{ width: 40 }}
                          image={DateImage}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          gutterBottom
                        >
                          Delivery date and time
                        </Typography>
                        <Typography variant="body2">
                          {moment(checkoutData?.pickup_date?.end_date).format(
                            "dddd , MMMM , Do YYYY , h:mm:ss a"
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    display={"flex"}
                    justifyContent="space-between"
                    alignContent={"center"}
                  >
                    <Grid
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignContent="center"
                      flexDirection={"row"}
                      gap={3}
                      paddingTop={2}
                    >
                      <Grid item>
                        <CardMedia
                          component="img"
                          sx={{ width: 40 }}
                          image={MethodImage}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          gutterBottom
                        >
                          Delivery Method
                        </Typography>
                        <Typography variant="body2">
                          {checkoutData?.delivery_method?.typeOfMethod || ""}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Button>
                      <EditIcon sx={{ color: "black" }} />
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default CheckoutDeliveryInformation
