import React, { useContext, useEffect } from "react"
import { useFormik } from "formik"
import { Button, Box, Grid, Typography, TextField } from "@mui/material"
import { pickUpAddSchema } from "../../utils/utils"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab"
import InputMask from "react-input-mask"
import { useMutation } from "@tanstack/react-query"
import { pickupDataForm } from "../../api"
import { useNavigate } from "react-router-dom"
import { MultiStepContext } from "../../Context/StepContext"
import { useCheckoutData } from "../../Context/CheckoutProvider"

const PickupAddressForm = () => {
  //StepContext
  const { activeStep, setActiveStep } = useContext(MultiStepContext)

  //handle next and back funtion
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  // navigate
  const navigate = useNavigate()

  // handling the back button render with form values
  const { checkoutData } = useCheckoutData()
  const orderData = checkoutData?.pickup_address

  // react mutate query

  const { mutate } = useMutation((data) => pickupDataForm(data), {
    onSuccess: (res) => {
      navigate(`/pickup/${res.data.DeliveryReqData._id}/step2`)
      handleNext()
    },
    onError: (err) => {
      console.log(err)
    },
  })
  useEffect(() => {
    setActiveStep(0)
  })

  const { values, handleChange, handleSubmit, touched, errors } = useFormik({
    initialValues: {
      name: orderData?.name || "",
      phone_num: orderData?.phone_num || "",
      city: orderData?.city || "",
      apt_office: orderData?.apt_office || "",
      floor: orderData?.floor || "",
      additional_details: orderData?.additional_details || "",
      address: orderData?.address || "",
    },
    validationSchema: pickUpAddSchema,
    enableReinitialize: true,
    onSubmit: (e) => {
      mutate(values)
    },
  })

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Box display={"flex"} justifyContent="center" alignContent={"center"}>
          <Box
            display={"flex"}
            justifyContent="center"
            alignContent={"center"}
            flexDirection={"column"}
          >
            <Grid container direction="column" padding={2}>
              <Typography variant="h4">Pick up Address</Typography>
              <Typography variant="body2">
                Tell us where to pick up the package
              </Typography>
            </Grid>
            <Box
              container
              sx={{
                bgcolor: "background.paper",
                width: 640,
                height: 150,
              }}
            >
              <Grid container padding={1} spacing={2}>
                <Grid item xs={16}>
                  <Typography variant="h5">Contact details</Typography>
                </Grid>
                <Grid
                  display="flex"
                  justifyContent={"space-evenly"}
                  alignItems="center"
                  gap={2}
                  padding={2}
                >
                  <Grid item>
                    <TextField
                      id="name"
                      name="name"
                      label="Full Name"
                      value={values.name}
                      placeholder="name"
                      onChange={handleChange}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item>
                    <InputMask
                      mask="+1 999 999 9999"
                      value={values.phone_num}
                      maskChar=" "
                      onChange={handleChange}
                    >
                      {() => (
                        <TextField
                          id="phone_num"
                          name="phone_num"
                          label="Phone Number"
                          error={touched.phone_num && Boolean(errors.phone_num)}
                          helperText={touched.phone_num && errors.phone_num}
                          variant="outlined"
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box
              container
              sx={{
                bgcolor: "background.paper",
                width: 640,
                height: 400,
              }}
            >
              <Grid container padding={1} spacing={2}>
                <Grid item xs={16}>
                  <Typography variant="h5">Address</Typography>
                </Grid>
                <Grid
                  display="flex"
                  justifyContent={"space-evenly"}
                  alignItems="center"
                  padding={2}
                >
                  <Grid container gap={2}>
                    <Grid item>
                      <TextField
                        id="city"
                        name="city"
                        label="City"
                        value={values.city}
                        placeholder="Ahmedabad"
                        onChange={handleChange}
                        error={touched.city && Boolean(errors.city)}
                        helperText={touched.city && errors.city}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="address"
                        name="address"
                        label="Address"
                        placeholder="Times Square"
                        value={values.address}
                        onChange={handleChange}
                        error={touched.address && Boolean(errors.address)}
                        helperText={touched.address && errors.address}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="apt_office"
                        name="apt_office"
                        label="Apt / Office"
                        value={values.apt_office}
                        placeholder="Times Now"
                        onChange={handleChange}
                        error={touched.apt_office && Boolean(errors.apt_office)}
                        helperText={touched.apt_office && errors.apt_office}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="floor"
                        name="floor"
                        label="Floor"
                        placeholder="9"
                        value={values.floor}
                        onChange={handleChange}
                        error={touched.floor && Boolean(errors.floor)}
                        helperText={touched.floor && errors.floor}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid padding={1}>
                <TextField
                  sx={{ width: 490 }}
                  multiline
                  rows={4}
                  id="additional_details"
                  name="additional_details"
                  label="Additonal Details for Stoovo"
                  placeholder="Additional Details"
                  value={values.additional_details}
                  onChange={handleChange}
                  error={
                    touched.additional_details &&
                    Boolean(errors.additional_details)
                  }
                  helperText={
                    touched.additional_details && errors.additional_details
                  }
                  variant="outlined"
                />
              </Grid>
            </Box>
            <Box padding={4}>
              <Grid
                display="flex"
                justifyContent={"space-evenly"}
                alignItems="center"
              >
                <Grid container gap={30}>
                  <Button disabled={activeStep === 0} variant="contained">
                    <KeyboardBackspaceIcon />
                    Back
                  </Button>
                  <Button type="submit" variant="contained">
                    Next
                    <KeyboardTabIcon />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </form>
    </>
  )
}

export default PickupAddressForm
