import Cookies from "js-cookie"
import api from "./api"

export const addUser = (data) => {
  return api.post(`/signup`, data).then((response) => {
    if (response.data.token) {
      Cookies.set("token", response.data.token)
    }
  })
}
export const addTypeOfAccount = (data) => {
  return api.post(`/type-of-acc`, data)
}

export const authLogin = (data) => {
  return api
    .post(`/login`, data)
    .then((response) => Cookies.set("token", response.data.token))
}

export const pickupDataForm = (data) => {
  console.log(data)
  return api.post(`/pickup-add`, data)
}

export const pickupTypeForm = (id, data) => {
  return api.post(`/pickup-method/${id}`, data)
}

export const deliveryAddForm = (id, data) => {
  return api.post(`/delivery-add/${id}`, data)
}

export const deliveryTypeForm = (id, data) => {
  return api.post(`/delivery-method/${id}`, data)
}

export const typeOfPackageApi = (id, data) => {
  return api.post(`/pickup-type/${id}`, data)
}

export const pickupTime = (id, data) => {
  return api.post(`/pickup-date/${id}`, data)
}

export const paymentStatus = (id, data) => {
  return api.post(`/payment-status/${id}`, data)
}

export const getOrderCheckout = (id) => {
  return api.get(`/checkout/${id}`)
}

export const getUser = () => {
  return api.get(`/me`)
}

export const deleteOrder = (id, data) => {
  return api.post(`/cancel-order/${id}`)
}

export const getOrderHistory = () => {
  return api.get(`/order-history`)
}
export const getActiveOrderHistory = () => {
  return api.get(`/active-order`)
}

export const forgotPassword = (data) => {
  return api.post(`/forgot-pass`, data)
}

export const typeOfPackages = () => {
  return api.get(`/type-of-packages`)
}

export const updateUserProfile = (data) => {
  return api.post(`/update-profile`, data)
}

export const getPayment = (id) => {
  return api.get(`/payment/${id}`)
}
