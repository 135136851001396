import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import React from "react"
import { getOrderHistory } from "../../api"
import OrderHistoryDelivery from "./OrderHistoryDelivery"
import OrderHistoryPackages from "./OrderHistoryPackages"
import OrderHistoryPickup from "./OrderHistoryPickup"
import AddressImage from "../../Images/Address.svg"
import Navbar from "../Navbar/Navbar"
import { useNavigate } from "react-router-dom"

const OrderHistory = () => {
  const navigate = useNavigate()
  const {
    data: response,
    isLoading,
    isError,
  } = useQuery(["Fetching-Order-Hisotry"], () => getOrderHistory())
  if (isLoading) {
    return <CircularProgress color="inherit" />
  }
  return (
    <>
      <Navbar />
      <Grid
        display={"flex"}
        justifyContent="flex-start"
        alignContent={"center"}
        padding={1}
      >
        <Button variant="contained" onClick={() => navigate("/profile")}>
          back
        </Button>
      </Grid>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Grid
          display={"flex"}
          justifyContent="center"
          alignContent="center"
          flexDirection={"column"}
          gap={2}
          marginTop={2}
        >
          <Typography variant="h3">Order History</Typography>
          {response?.data?.order_historyData?.map((item, index) => (
            <div key={index}>
              <Card sx={{ width: "640px", height: "330px" }}>
                <OrderHistoryPickup packageData={item} />
                <OrderHistoryDelivery packageData={item} />
                <OrderHistoryPackages packageData={item} />
                <Button fullWidth variant="outlined">
                  Repeat order
                </Button>
              </Card>
            </div>
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default OrderHistory
