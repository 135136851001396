import { CardMedia, Grid, Typography } from "@mui/material"
import React from "react"
import AddressImage from "../../Images/Address.svg"

const OrderHistoryDelivery = ({ packageData }) => {
  return (
    <Grid
      display={"flex"}
      justifyContent={"flex-start"}
      alignContent="center"
      flexDirection={"row"}
      gap={3}
      padding={2}
    >
      <CardMedia component="img" sx={{ width: 50 }} image={AddressImage} />
      <Grid display={"flex"} flexDirection="column">
        <Typography variant="body-2" color={"text.secondary"}>
          To
        </Typography>
        <Typography variant="body-2">
          {packageData?.delivery_address?.name || " "} |{" "}
          {packageData?.delivery_address?.phone_num || ""}
        </Typography>
        <Typography variant="body-2">
          {packageData?.delivery_address?.address || ""}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default OrderHistoryDelivery
