import { Box, Grid, Typography, Alert } from "@mui/material";
import React from "react";

import CustomCard from "./CardComponent/CustomCard";

const TypeOfPackageForm = () => {
  return (
    <>
      <Box
        display={"flex"}
        justifyContent="flex-start"
        alignContent={"flex-start"}
        flexDirection={"column"}
      >
        <Box
          display={"flex"}
          justifyContent="flex-start"
          alignContent={"flex-start"}
        >
          <Box display={"flex"} justifyContent="center" alignContent={"center"}>
            <Grid
              display={"flex"}
              justifyContent={"flex-start"}
              alignContent={"flex-start"}
              direction="column"
              gap={1}
              padding={2}
            >
              <Grid item>
                <Typography textAlign={"left"} variant="h4">
                  I want to send{" "}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  Pick type and amount of packages that you want to send
                </Typography>
              </Grid>
              <Grid item>
                <Alert severity="warning">
                  Stoovo doesn't provide packaging materials
                </Alert>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <CustomCard />
      </Box>
    </>
  );
};

export default TypeOfPackageForm;
