import Cookies from "js-cookie"
import * as yup from "yup"
import { Navigate } from "react-router-dom"

// handling validation using yup
export const validationSchema = yup.object({
  email: yup.string().email().required("Email is required"),
  password: yup.string().min(8).required("Requierd"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must be same")
    .required("Required"),
})

//handling loginValidation using Yup
export const loginValidationSchema = yup.object({
  email: yup.string().email().required("Email is required"),
  password: yup.string().min(8).required("Requierd"),
})

//handling pickupAddSchema using Yup
export const pickUpAddSchema = yup.object({
  name: yup.string().max(30).required("Full Name is required "),
  city: yup.string().required(),
  phone_num: yup.string().required("Phone number is required"),
  address: yup.string().required(),
  apt_office: yup.string().required("Apartment / Office Name is Required "),
  floor: yup.string().required("Floor Number is required"),
  additional_details: yup.string().notRequired(),
})

//handling forgotPasswordSchema using Yup
export const forgotPasswordSchema = yup.object({
  email: yup.string().email().required("Email is required"),
})

//handling deliveryAddSchema using Yup
export const deliveryAddSchema = yup.object({
  name: yup.string().max(30).required("Full Name is required "),
  city: yup.string().required(),
  phone_num: yup.string().required("Phone number is required"),
  address: yup.string().required(),
  apt_office: yup.string().required("Apartment / Office Name is Required "),
  floor: yup.string().required("Floor Number is required"),
  additional_details: yup.string().notRequired(),
})

// handling Update Profile Email Validation
export const updateProfileSchema = yup.object({
  email: yup.string().email().required("Email is required"),
})

// getToken
export const getToken = () => {
  return Cookies.get("token")
}

// removeToken
export const removeToken = () => {
  return Cookies.remove("token")
}

// steps
export const steps = [
  " Pickup ",
  " Pickup Method",
  " Delivery",
  " Delivery Method",
  "Package Type",
  "Pickup Date ",
  "Checkout",
]

export const RequireAuth = ({ children }) => {
  if (!getToken()) {
    return <Navigate to={"/login"} />
  }
  return children
}
