import React, { useEffect } from "react"
import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import {} from "../../../Context/StepContext"
import OrderSummary from "./OrderSummary"
import CheckoutPickupInformation from "./CheckoutPickupInformation"
import CheckoutDeliveryInformation from "./CheckoutDeliveryInformation"
import CheckoutPackageInformation from "./CheckoutPackageInformation"
import { PackageProvider } from "../../../Context/PackageProvider"
import { useCheckoutData } from "../../../Context/CheckoutProvider"

const Checkout = () => {
  const { checkoutData, isLoading, refetch } = useCheckoutData()

  useEffect(() => {
    refetch()
  }, [refetch])

  if (isLoading) {
    return <CircularProgress color="inherit" />
  }
  return (
    <>
      <PackageProvider>
        <Box
          display={"flex"}
          justifyContent="center"
          alignContent={"center"}
          flexDirection="column"
        >
          <Box
            display={"flex"}
            justifyContent="flex-start"
            alignContent={"flex-start"}
            gap={5}
          >
            <Box
              display={"flex"}
              justifyContent="center"
              alignContent={"center"}
              flexDirection={"column"}
            >
              <Grid container direction="column" padding={2}>
                <Typography variant="h4">Checkout</Typography>
              </Grid>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                <Grid
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignContent={"center"}
                  flexDirection={"column"}
                  gap={2}
                >
                  {/* Pickup Information */}

                  <CheckoutPickupInformation checkoutData={checkoutData} />

                  {/* Delivery Pickup Information */}
                  <CheckoutDeliveryInformation
                    checkoutData={checkoutData}
                    refetch={refetch}
                  />
                  {/* Package type information */}
                  <CheckoutPackageInformation
                    checkoutData={checkoutData}
                    isLoading={isLoading}
                    refetch={refetch}
                  />
                </Grid>
              </Box>
            </Box>
            {/* Order Summary */}
            <OrderSummary />
          </Box>
        </Box>
      </PackageProvider>
    </>
  )
}

export default Checkout
