import React, { useContext, useEffect } from "react"
import {
  Box,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Button,
  TextField,
} from "@mui/material"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab"
import { MultiStepContext } from "../../Context/StepContext"
import { useMutation, useQuery } from "@tanstack/react-query"
import { getOrderCheckout, pickupTypeForm } from "../../api"
import { useNavigate, useParams } from "react-router-dom"
import { useFormik } from "formik"
import { useCheckoutData } from "../../Context/CheckoutProvider"

const PickupAddressTypeForm = () => {
  let { id } = useParams()

  //navigate
  const navigate = useNavigate()

  // handling the back button render with form values
  const { checkoutData } = useCheckoutData()
  const orderData = checkoutData?.pickup_address

  // active step and logic for next and previous step
  const { activeStep, setActiveStep } = useContext(MultiStepContext)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  // toggle button logic for changing the option
  const handleToogleChange = (event, newAlignment) => {
    setFieldValue("typeOfMethod", newAlignment)
  }

  useEffect(() => {
    setActiveStep(1)
  })
  // reaqct-mutate-query

  const { mutate } = useMutation((data) => pickupTypeForm(id, data), {
    onSuccess: (res) => {
      navigate(`/pickup/${id}/step3`)
    },
    onError: (err) => {
      console.log(err)
    },
  })

  // formik
  const { values, handleChange, handleSubmit, touched, errors, setFieldValue } =
    useFormik({
      initialValues: {
        typeOfMethod: orderData?.typeOfMethod || "",
        additional_info: orderData?.additional_info || "",
      },
      enableReinitialize: true,
      onSubmit: (values) => {
        mutate(values)
        handleNext()
      },
    })

  return (
    <>
      <Box
        display={"flex"}
        justifyContent="center"
        alignContent={"center"}
        flexDirection="column"
      >
        <Box
          display={"flex"}
          justifyContent="flex-start"
          alignContent={"flex-start"}
        >
          <Box
            display={"flex"}
            justifyContent="center"
            alignContent={"center"}
            flexDirection={"column"}
          >
            <Grid container direction="column" padding={2}>
              <Typography variant="h4">Pick up Method</Typography>
              <Typography variant="body2">
                Tell us where to deliever package
              </Typography>
            </Grid>
          </Box>
        </Box>
        <form noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              boxShadow: 2,
              width: "640px",
              height: "450px",
              bgcolor: (theme) =>
                theme.palette.mode === "dark" ? "#101010" : "#fff",
              color: (theme) =>
                theme.palette.mode === "dark" ? "grey.300" : "grey.800",
              p: 3,
              m: 1,
              borderRadius: 2,
            }}
          >
            <Grid
              display={"flex"}
              justifyContent="space-between"
              flexDirection={"column"}
            >
              <Grid item>
                <ToggleButtonGroup
                  id="typeOfMethod"
                  color="primary"
                  value={values.typeOfMethod}
                  exclusive
                  onChange={handleToogleChange}
                >
                  <ToggleButton
                    sx={{ width: "310px", height: "210px" }}
                    value="Direct_handoff"
                  >
                    <Grid
                      display="flex"
                      justifyContent={"flex-start"}
                      alignContent={"flex-start"}
                      flexDirection={"column"}
                    >
                      <Grid item padding={5}>
                        <Typography gutterBottom varaint="h4">
                          Direct handoff
                        </Typography>
                      </Grid>
                      <Typography paragraph={"true"} varaint="body-2">
                        Meet your Stoover at the door to ensure a secure handoff
                      </Typography>
                    </Grid>
                  </ToggleButton>
                  <ToggleButton
                    sx={{ width: "310px", height: "210px" }}
                    value="Leave_On_Door_Step"
                  >
                    <Grid
                      display="flex"
                      justifyContent={"flex-start"}
                      alignContent={"flex-start"}
                      flexDirection={"column"}
                    >
                      <Grid item padding={5}>
                        <Typography gutterBottom varaint="h4">
                          Leave at doorStep
                        </Typography>
                      </Grid>
                      <Typography paragraph={"true"} varaint="body-2">
                        Place items outside your door in advance of your pickup
                        time
                      </Typography>
                    </Grid>
                  </ToggleButton>
                </ToggleButtonGroup>
                <Grid padding={2}>
                  <TextField
                    sx={{ width: 600 }}
                    multiline
                    rows={4}
                    id="additional_info"
                    name="additional_info"
                    label="Additonal Details for Stoover"
                    placeholder="Additional Details"
                    value={values.additional_info}
                    onChange={handleChange}
                    error={
                      touched.additional_info && Boolean(errors.additional_info)
                    }
                    helperText={
                      touched.additional_info && errors.additional_info
                    }
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box padding={4}>
                <Grid
                  display="flex"
                  justifyContent={"space-evenly"}
                  alignItems="center"
                >
                  <Grid container gap={40}>
                    <Button
                      onClick={() => {
                        navigate(`/pickup/${id}/step1`)
                        handleBack()
                      }}
                      disabled={activeStep === 0}
                      variant="contained"
                    >
                      <KeyboardBackspaceIcon />
                      Back
                    </Button>
                    <Button type="submit" variant="contained">
                      Next
                      <KeyboardTabIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Box>
        </form>
      </Box>
    </>
  )
}

export default PickupAddressTypeForm
