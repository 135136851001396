import { CardMedia, Grid, Typography } from "@mui/material"
import React from "react"
import AddressImage from "../../Images/Address.svg"
import moment from "moment"

const OrderHistoryPickup = ({ packageData }) => {
  return (
    <>
      <Grid
        display={"flex"}
        justifyContent="flex-start"
        alignContent={"flex-start"}
        padding={2}
      >
        <Typography variant="h6">
          Order from{" "}
          {moment(packageData?.pickup_date?.start_date).format(
            "dddd, MMMM Do YYYY, h:mm:ss a"
          )}{" "}
        </Typography>
      </Grid>
      <Grid
        display={"flex"}
        justifyContent={"flex-start"}
        alignContent="center"
        flexDirection={"row"}
        gap={3}
        padding={2}
      >
        <CardMedia component="img" sx={{ width: 50 }} image={AddressImage} />
        <Grid display={"flex"} flexDirection="column">
          <Typography variant="body-2" color={"text.secondary"}>
            From
          </Typography>

          <Typography variant="body-2">
            {packageData?.pickup_address?.name || ""} |{" "}
            {packageData?.pickup_address?.phone_num || ""}
          </Typography>
          <Typography variant="body-2">
            {packageData?.pickup_address?.address || ""}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

export default OrderHistoryPickup
