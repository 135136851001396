import React, { useContext, useEffect, useState } from "react"
import {
  Box,
  Typography,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Button,
} from "@mui/material"
import { MultiStepContext } from "../../Context/StepContext"
import { useMutation } from "@tanstack/react-query"
import { useNavigate, useParams } from "react-router-dom"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab"
import { pickupTime } from "../../api"
import moment from "moment"
import DatePicker from "react-date-picker"
import TimeRangePicker from "@wojtekmaj/react-timerange-picker"

const PickUpDateAndTime = () => {
  // id
  let { id } = useParams()

  //navigate
  const navigate = useNavigate()

  // active step and logic for next and previous step
  const { activeStep, setActiveStep } = useContext(MultiStepContext)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  useEffect(() => {
    setActiveStep(5)
  })

  // toggle button logic for changing the option

  const [alignment, setAlignment] = React.useState("fixed")
  const [timeValue, setTimeValue] = useState(["11:00", "19:00"])
  const [dateValue, setDateValue] = useState(new Date())

  const handleToogleChange = (event, newAlignment) => {
    setAlignment(newAlignment)
  }
  const dateOnChange = (stat) => {
    setDateValue(stat)
  }

  const timeOnChange = (stat) => {
    setTimeValue(stat)
  }

  // react-query
  const { mutate } = useMutation((data) => pickupTime(id, data), {
    onSuccess: (res) => {
      navigate(`/pickup/${id}/step7`)
      handleNext()
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    const delivery_terms = alignment
    const start_date =
      moment(dateValue).format("YYYY-MM-DD") + " " + timeValue[0]
    const end_date = moment(dateValue).format("YYYY-MM-DD") + " " + timeValue[1]
    mutate({ delivery_terms, start_date, end_date })
  }

  return (
    <>
      <Box
        display={"flex"}
        justifyContent="center"
        alignContent={"center"}
        flexDirection="column"
      >
        <Box
          display={"flex"}
          justifyContent="flex-start"
          alignContent={"flex-start"}
        >
          <Box
            display={"flex"}
            justifyContent="center"
            alignContent={"center"}
            flexDirection={"column"}
          >
            <Grid container direction="column" padding={2}>
              <Typography variant="h4">Pickup Date and time </Typography>
              <Typography variant="body2">
                Tell us where to deliever package
              </Typography>
            </Grid>
          </Box>
        </Box>
        <form noValidate onSubmit={handleSubmit}>
          <Box
            sx={{
              boxShadow: 2,
              width: "620px",
              height: "240px",
              bgcolor: (theme) =>
                theme.palette.mode === "dark" ? "#101010" : "#fff",
              color: (theme) =>
                theme.palette.mode === "dark" ? "grey.300" : "grey.800",
              p: 3,
              m: 1,
              borderRadius: 2,
            }}
          >
            <Typography variant="h5">Delivery Terms</Typography>
            <Grid
              display={"flex"}
              justifyContent="space-between"
              flexDirection={"column"}
            >
              <Grid item>
                <ToggleButtonGroup
                  id="typeofpickup"
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleToogleChange}
                >
                  <ToggleButton
                    sx={{ width: "310px", height: "210px" }}
                    value={"fixed"}
                  >
                    <Grid
                      display="flex"
                      justifyContent={"flex-start"}
                      alignContent={"flex-start"}
                      flexDirection={"column"}
                    >
                      <Grid item padding={5}>
                        <Typography gutterBottom varaint="h4">
                          $3
                        </Typography>
                      </Grid>
                      <Typography paragraph={"true"} varaint="body-2">
                        Scheduled pickup with fixed time set by Stoovo
                      </Typography>
                    </Grid>
                  </ToggleButton>
                  <ToggleButton
                    sx={{ width: "310px", height: "210px" }}
                    value={"ASAP"}
                  >
                    <Grid
                      display="flex"
                      justifyContent={"flex-start"}
                      alignContent={"flex-start"}
                      flexDirection={"column"}
                    >
                      <Grid item padding={5}>
                        <Typography gutterBottom varaint="h4">
                          $7
                        </Typography>
                      </Grid>
                      <Typography paragraph={"true"} varaint="body-2">
                        Asap Pickup Time
                      </Typography>
                    </Grid>
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              boxShadow: 2,
              width: "620px",
              height: "180px",
              bgcolor: (theme) =>
                theme.palette.mode === "dark" ? "#101010" : "#fff",
              color: (theme) =>
                theme.palette.mode === "dark" ? "grey.300" : "grey.800",
              p: 3,
              m: 1,
              borderRadius: 2,
              marginTop: 5,
            }}
          >
            <Typography variant="h5">Pickup date and time</Typography>
            <Grid
              display={"flex"}
              justifyContent="space-between"
              flexDirection={"column"}
              padding={2}
            >
              <Grid item paddingTop={1}>
                <DatePicker onChange={dateOnChange} value={dateValue} />
              </Grid>
              <Grid item paddingTop={1}>
                <TimeRangePicker
                  onChange={timeOnChange}
                  value={timeValue}
                  format={"hh:mm a"}
                  disableClock={true}
                  minTime="10:00:00"
                  maxTime="19:00:00"
                />
              </Grid>
            </Grid>
          </Box>
          <Box padding={4}>
            <Grid display="flex" justifyContent={"center"} alignItems="center">
              <Grid container gap={50}>
                <Button
                  onClick={() => {
                    navigate(`/pickup/${id}/step5`)
                    handleBack()
                  }}
                  disabled={activeStep === 0}
                  variant="contained"
                >
                  <KeyboardBackspaceIcon />
                  Back
                </Button>
                <Button type="submit" variant="contained">
                  Next
                  <KeyboardTabIcon />
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </>
  )
}

export default PickUpDateAndTime
