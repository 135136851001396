import React, { Suspense } from "react"
import { Routes, Route, Navigate, Outlet } from "react-router-dom"
import CircularProgress from "@mui/material/CircularProgress"
import withoutAuthentication from "../utils/withoutAuthentication"
import { UserProvider } from ".././Context/UserProvider"

import withAuthentication from "../utils/withAuthentication"
import ForgotPasswordSuccess from "../Components/ForgotPassword/ForgotPasswordSuccess"
import { StepContext } from "../Context/StepContext"
import PickupAddressForm from "../Components/Forms/PickupAddressForm"
import PickupAddressTypeForm from "../Components/Forms/PickupAddressTypeForm"
import DeliveryAddressForm from "../Components/Forms/DeliveryAddressForm"
import DeliveryAddressTypeForm from "../Components/Forms/DeliveryAddressTypeForm"
import TypeOfPackageForm from "../Components/Forms/TypeOfPackageForm"
import PickUpDateAndTime from "../Components/Forms/PickUpDateAndTime"
import Checkout from "../Components/Forms/Checkout/Checkout"
import { CheckoutProvider } from "../Context/CheckoutProvider"
import OrderHistory from "../Components/OrderHistory/OrderHistory"
import { PackageProvider } from "../Context/PackageProvider"
const ForgotPassword = React.lazy(() =>
  import("../Components/ForgotPassword/ForgotPassword")
)
const Dashboard = React.lazy(() => import("../Components/Dashboard/Dashboard"))
const Signup = React.lazy(() => import("../Components/Signup/Signup"))
const AccType = React.lazy(() => import("../Components/AccountType/AccType"))
const Profile = React.lazy(() => import("../Components/Profile/Profile"))
const Login = React.lazy(() => import("../Components/Login/Login"))
const PickupAddress = React.lazy(() =>
  import("../Components/PickupAddress/PickupAddress.js")
)
const OrderTracking = React.lazy(() =>
  import("../Components/OrderTracking/OrderTracking.js")
)

const Router = () => {
  // UnAuthenticated Routes Outlet
  const UnAuthenticated = () => <Outlet />
  // Authenticated Routes Outlet
  const Authenticated = () => {
    return (
      <>
        <UserProvider>
          <StepContext>
            <CheckoutProvider>
              <PackageProvider>
                <Outlet />
              </PackageProvider>
            </CheckoutProvider>
          </StepContext>
        </UserProvider>
      </>
    )
  }
  return (
    <>
      <Suspense
        fallback={
          <div className="circular-progress">
            <CircularProgress color="inherit" />
          </div>
        }
      >
        <Routes>
          {/* Authenticated App components only  */}
          <Route path="/*" element={withAuthentication(Authenticated)}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="type-of-account" element={<AccType />} />
            <Route path="profile" element={<Profile />} />
            <Route path="pickup/*" element={<PickupAddress />}>
              <Route path="step1" element={<PickupAddressForm />}></Route>
              <Route path=":id/step1" element={<PickupAddressForm />}></Route>
              <Route
                path=":id/step2"
                element={<PickupAddressTypeForm />}
              ></Route>
              <Route path=":id/step3" element={<DeliveryAddressForm />}></Route>
              <Route
                path=":id/step4"
                element={<DeliveryAddressTypeForm />}
              ></Route>
              <Route path=":id/step5" element={<TypeOfPackageForm />}></Route>
              <Route path=":id/step6" element={<PickUpDateAndTime />}></Route>
              <Route path=":id/step7" element={<Checkout />}></Route>
            </Route>
            <Route path="order-tracking/:id" element={<OrderTracking />} />
            <Route path="order-history" element={<OrderHistory />} />
          </Route>

          {/* UnAuthenticated App Routes only */}

          <Route path="/" element={withoutAuthentication(UnAuthenticated)}>
            <Route index element={<Navigate to={"login"} />} />
            <Route path="signup" element={<Signup />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route
              path="forgot-password-success"
              element={<ForgotPasswordSuccess />}
            />
          </Route>
          <Route path="*" element={<div>404</div>}></Route>
        </Routes>
      </Suspense>
    </>
  )
}

export default Router
