import React, { useContext, useEffect, useState } from "react"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { useNavigate, useParams } from "react-router-dom"
import { MultiStepContext } from "../../../Context/StepContext"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab"
import { typeOfPackageApi, typeOfPackages } from "../../../api"

import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"

const CustomCard = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [packageData, setPackageData] = useState([])
  //type of packages

  useQuery(["fetch-packages"], typeOfPackages, {
    onSuccess: (res) => setPackageData(res.data.data),
  })

  // active step and logic for next and previous step
  const { activeStep, setActiveStep } = useContext(MultiStepContext)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const IncNum = (index) => {
    packageData[index].currCount = packageData[index].currCount + 1
    setPackageData([...packageData])
  }
  const DecNum = (index) => {
    if (packageData[index].currCount >= 1) {
      packageData[index].currCount = packageData[index].currCount - 1
      setPackageData([...packageData])
    }
  }

  // react-query
  const { mutate } = useMutation((data) => typeOfPackageApi(id, data), {
    onSuccess: (res) => {
      navigate(`/pickup/${id}/step6`)
      handleNext()
    },
    onError: (err) => {
      console.log(err)
    },
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    let mapped = packageData?.map((iterator) => ({
      [iterator.field]: iterator.currCount,
    }))
    var newObject = Object.assign({}, ...mapped)
    mutate(newObject)
  }
  useEffect(() => {
    setActiveStep(4)
  }, [setActiveStep])

  return (
    <>
      <form noValidate onSubmit={handleSubmit}>
        {packageData?.map((packages, index) => {
          return (
            <div key={index}>
              <Grid display="flex" flexDirection={"column"}>
                <Grid item padding={1}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "680px",
                      height: "176px",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Grid
                          container
                          display={"flex"}
                          justifyContent="flex-start"
                          alignContent="flex-start"
                          gap={2}
                        >
                          <Grid item>
                            <CardMedia
                              component="img"
                              sx={{ width: "160px" }}
                              image={packages?.image}
                            />
                          </Grid>
                          <Grid item sx={{ width: "264px", padding: "4px" }}>
                            <Typography variant="h4">
                              {packages?.key}
                            </Typography>
                            <Typography
                              paragraph
                              variant="subtitle1"
                              color="text.secondary"
                            >
                              {packages?.value}
                            </Typography>
                          </Grid>
                          <Grid
                            display={"flex"}
                            justifyContent="center"
                            alignItems={"center"}
                            gap={2}
                          >
                            <Button
                              onClick={() => {
                                DecNum(index)
                                if (packages.currCount === 0) {
                                  alert(
                                    "You need atleast one order from the below !"
                                  )
                                }
                              }}
                            >
                              <RemoveIcon />
                            </Button>
                            <Grid item>
                              <Typography id="currCount">
                                {packages?.currCount}
                              </Typography>
                            </Grid>
                            <Button
                              onClick={() => {
                                IncNum(index)
                              }}
                            >
                              <AddIcon />
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </div>
          )
        })}
        <Box padding={4}>
          <Grid display="flex" justifyContent={"center"} alignItems="center">
            <Grid container gap={55}>
              <Button
                onClick={() => {
                  navigate(`/pickup/${id}/step4`)
                  handleBack()
                }}
                disabled={activeStep === 0}
                variant="contained"
              >
                <KeyboardBackspaceIcon />
                Back
              </Button>
              <Button type="submit" variant="contained">
                Next
                <KeyboardTabIcon />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  )
}
export default CustomCard
