import React from "react"
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { usePackageData } from "../../../Context/PackageProvider"

const CheckoutPackageInformation = ({ checkoutData, isLoading }) => {
  const { packageData } = usePackageData()

  const getImage = (value) => {
    const record = packageData.find((item) => item.field === value) || {}
    return record.image
  }
  const packageTypeData = checkoutData?.pickup_type
  const boxes = Object.keys(packageTypeData || {})
    .filter((value) => value !== "_id")
    .map((value) => {
      return {
        name: value,
        value: packageTypeData[value],
        image: getImage(value),
      }
    })
    .filter((value) => value.value !== 0)

  if (isLoading) {
    return <CircularProgress color="inherit" />
  }
  return (
    <>
      <Grid item>
        <Grid item>
          <Card sx={{ width: "640px", height: "280px" }}>
            <CardContent>
              <Grid item>
                <Typography variant="h6">Packages</Typography>
              </Grid>
              {boxes &&
                boxes?.map((packages, index) => (
                  <Grid
                    display={"flex"}
                    justifyContent="space-between"
                    alignContent={"center"}
                    key={index}
                  >
                    <Grid
                      display={"flex"}
                      justifyContent={"center"}
                      alignContent="center"
                      flexDirection={"row"}
                      gap={3}
                      paddingTop={2}
                    >
                      <CardMedia
                        component="img"
                        sx={{ width: 40 }}
                        image={packages?.image}
                      />
                      <Grid
                        display={"flex"}
                        justifyContent="center"
                        alignItems={"center"}
                        gap={1}
                      >
                        <Typography variant="h6">{packages?.name}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          x{packages?.value}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Button>
                      <EditIcon sx={{ color: "black" }} />
                    </Button>
                  </Grid>
                ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

export default CheckoutPackageInformation
